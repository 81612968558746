import React from "react";
import { useIntl } from "gatsby-plugin-intl";

import { Layout } from "../components/layout";
import { SEO } from "../components/seo";
import { Post } from '../components/post';

type PageProps = {
	pageContext: {
		title: string,
		body: string,
		timestamp: string,
		image: any,
	},
};

export const ClarificationPage: React.FC<PageProps> = (props) => {
	const { pageContext: { title, body, timestamp, image }} = props;
  const intl = useIntl();

	const str = [] as string[];
	str.push(intl.formatMessage({ id: 'Clarification' }));
	str.push(title);

  return (
    <Layout path={'x'} nav={false}>
      <SEO title={str.join(' – ')} />
      <Post title={title} body={body} timestamp={timestamp} image={image} />
    </Layout>
  );
}


export default ClarificationPage;
