import React from "react";
import { useIntl, Link } from "gatsby-plugin-intl";
import ReactMarkdown from 'react-markdown'
import { GatsbyImage, getImage } from "gatsby-plugin-image";

type PostProps = {
	title: string,
	body: string,
	timestamp: string,
	image: any,
};

export const Post: React.FC<PostProps> = ({ title, body, timestamp, image }) => {
  const intl = useIntl();

	let img;
  if (image) {
    const file = getImage(image.localFile);
    img = (
      <div className="post-image">
        <GatsbyImage image={file} alt='' />
      </div>
    );
	}

  return (
    <div className="post" id="post">
			{img}
			<div className="clarification">{intl.formatMessage({ id: 'Clarification' })}</div>
      <h2>{title}</h2>
			<time dateTime={timestamp}>{timestamp}</time>
      <div className="post-text">
        <ReactMarkdown>{body}</ReactMarkdown>
      </div>
    </div>
  )
};
